 // web app's Firebase configuration
 var firebaseConfig = {
    apiKey: "AIzaSyBm4ZUHQevHvkbyOQOJpomtWJ0TLxLBfXY",
    authDomain: "foundpad-app.firebaseapp.com",
    databaseURL: "https://foundpad-app.firebaseio.com",
    projectId: "foundpad-app",
    storageBucket: "foundpad-app.appspot.com",
    messagingSenderId: "246002686685",
    appId: "1:246002686685:web:292e0c06f76a5606"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);